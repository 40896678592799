/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fafafb;
}

.ffarial { font-family: "Arial", "Helvetica", sans-serif; }

.semibold, .semibold_a, .semibold_a * { font-weight: 600; }

span.required { color: #e04949; font-weight: 700; }

.bgcblue, .bgcblue_h:hover { background-color: #1e6496; }
.bgcblue_i, .bgcblue_h_i:hover { background-color: #1e6496 !important; }
.bgcdblue, .bgcdblue_h:hover { background-color: #003955; }
.bgcdblue_i, .bgcdblue_h_i:hover { background-color: #003955 !important; }

.cblue, .cblue_h:hover { color: #1e6496; }
.cblue_i, .cblue_h_i:hover { color: #1e6496 !important; }
.cdblue, .cdblue_h:hover { color: #003955; }
.cdblue_i, .cdblue_h_i:hover { color: #003955 !important; }

.brcblue, .brcblue_h:hover { border-color: #1e6496; }
.brcblue_i, .brcblue_h_i:hover { border-color: #1e6496 !important; }
.brcdblue, .brcdblue_h:hover { border-color: #003955; }
.brcdblue_i, .brcdblue_h_i:hover { border-color: #003955 !important; }

p { margin-bottom: 16px; }
p + p, .subheader + p, .subsubheader + p, .subsubsubheader + p { margin-top: -4px; }

.link {
  color: #1e6496;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }



.form-block-container {
  background: #fff;
  padding: 40px;
}
  .form-popup-container .form-block-container {
    border: 4px solid #1e6496;
    min-width: 420px;
    max-width: 700px;
  }

  .form-block-container > .subsubheader {
    margin-top: -12px;
  }

  .form-wrapper {
    margin: -22px 0px 0px -22px;
  }
    .form-wrapper #form-success-message {
      padding: 22px 0px 0px 22px;
    }

    .form {
      position: relative;
    }
      .form-row {
        padding: 22px 0px 0px 22px;
      }
        .form-label {
          font-weight: 300;
          margin-bottom: 7px;
        }
          .form-row-checkbox .form-label,
          .form-row-checkboxlist .form-field > div > label > span > span + span,
          .form-row-radiolist .form-field > div > label > span > span + span {
            font-size: 14px;
            display: block;
            margin: 0px 0px 0px 10px;
            cursor: pointer;
          }
            .form-row-checkbox .form-label label {
              cursor: pointer;
            }

        .form-field {
          /*width: 100%;*/
        }
          .input-container {
            background-color: #fff;
            border: 1px solid #979797;
            padding: 7px 10px 6px;
            position: relative;
            z-index: 1;
          }
            .input-container.error {
              border-color: #e04949;
            }

            .input-container input,
            .input-container textarea,
            .input-container select {
              background: transparent;
              font-size: 1em;
              font-weight: 400;
              line-height: 1.3;
              border: 0px;
              padding: 0px;
              margin: 0px;
            }
            .input-container input:not([type="radio"]):not([type="checkbox"]),
            .input-container textarea,
            .input-container select {
              width: 100%;
              min-width: 100%;
              max-width: 100%;
            }

          .form-error {
            position: relative;
          }
            .errorMessage {
              color: #fff;
              background: #e04949;
              font-size: 12px;
              line-height: 1.3;
              padding: 3px 6px;
            }


          .form-row-checkboxlist .form-field > div + div,
          .form-row-radiolist .form-field > div + div {
            margin: 8px 0px 0px;
          }
            .form-row-checkboxlist .form-field > div > label,
            .form-row-radiolist .form-field > div > label {
              display: inline-block;
            }

            .form-row-checkbox .form-field-wrapper,
            .form-row-checkboxlist .form-field > div > label > span,
            .form-row-radiolist .form-field > div > label > span {
              display: inline-flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
            }
              .form-row-checkbox .form-field,
              .form-row-checkboxlist .form-field > div,
              .form-row-radiolist .form-field > div {
                align-self: flex-start;
                position: relative;
              }
                .form-row-checkbox .form-field input,
                .form-row-checkboxlist .form-field > div > input,
                .form-row-radiolist .form-field > div > input {
                  border: none;
                  width: 0px;
                  height: 0px;
                  padding: 0px;
                  margin: auto;
                  top: 0px;
                  left: 2px;
                  bottom: 0px;
                  position: absolute;
                  opacity: 0;
                  z-index: -1;
                }

                .form-row-checkbox .form-field > span,
                .form-row-checkboxlist .form-field > div > label > span > span:first-child,
                .form-row-radiolist .form-field > div > label > span > span:first-child {
                  content: '';
                  color: transparent;
                  background: #fff;
                  border: 1px solid #979797;
                  font-size: 0px;
                  line-height: 0px;
                  display: block;
                  width: 22px;
                  min-width: 22px;
                  height: 22px;
                  position: relative;
                  cursor: pointer;
                }
                .form-row-radiolist .form-field > div > label > span > span:first-child {
                  border-radius: 50%;
                  width: 18px;
                  min-width: 18px;
                  height: 18px;
                  margin: 1px;
                }
                  .form-row-checkbox .form-field.error > span,
                  .form-row-checkboxlist .form-field.error > div > label > span > span:first-child,
                  .form-row-radiolist .form-field.error > div > label > span > span:first-child {
                    border-color: #e04949;
                  }

                  .form-row-checkbox .form-field > span label {
                    content: '';
                    color: transparent;
                    display: block;
                    font-size: 0px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    margin: auto;
                    cursor: pointer;
                  }
                    .form-row-checkbox .form-field > span label:after,
                    .form-row-checkboxlist .form-field > div > label > span > span:first-child:after,
                    .form-row-radiolist .form-field > div > label > span > span:first-child:after {
                      content: '';
                      background: url('/i/icon-tick.svg') no-repeat 50% 50%/12px auto transparent;
                      position: absolute;
                      top: 3px;
                      left: 3px;
                      right: 3px;
                      bottom: 3px;
                      margin: auto;
                      visibility: hidden;
                      opacity: 0;
                      transition: visibility 0s ease 0.3s, opacity 0.3s ease 0s;
                    }
                    .form-row-radiolist .form-field > div > label > span > span:first-child:after {
                      background: #1e6496;
                      border-radius: 50%;
                    }
                      .form-row-checkbox .form-field input:checked + span label:after,
                      .form-row-checkboxlist .form-field > div > input:checked + label > span > span:first-child:after,
                      .form-row-radiolist .form-field > div > input:checked + label > span > span:first-child:after {
                        visibility: unset;
                        opacity: 1;
                        transition: visibility 0s ease 0s, opacity 0.3s ease 0s;
                      }


        .form-row-name-captcha .form-field,
        .form-row-name-captcha .captcha-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
        }
          .form-row-name-captcha .captcha-container {
            align-items: stretch;
          }
            .form-row-name-captcha .captcha-container img {
              border: 1px solid #979797;
            }

            .form-row-name-captcha .captcha-container .refresh-button.button-style {
              min-width: unset;
              max-width: unset;
              padding: 0px;
            }
              .form-row-name-captcha .captcha-container .refresh-button.button-style span {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;
                font-size: 0;
                height: 100%;
                min-width: 38px;
              }
                .form-row-name-captcha .captcha-container .refresh-button.button-style span:after {
                  content: '';
                  display: block;
                  background: url('/i/refresh-button-icon--black.svg') no-repeat 50% 50%/18px auto transparent;
                  border: solid #979797;
                  border-width: 1px 0px;
                  line-height: 0;
                  width: 100%;

                  -webkit-transition: background-image .2s ease-in-out 0s;
                  -moz-transition: background-image .2s ease-in-out 0s;
                  -o-transition: background-image .2s ease-in-out 0s;
                  transition: background-image .2s ease-in-out 0s;
                }
                  .form-row-name-captcha .captcha-container .refresh-button.button-style:hover span:after {
                    background-image: url('/i/refresh-button-icon--white.svg');
                  }

          .form-row-name-captcha .captcha-container + div {
            flex-grow: 1;
          }


    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
      .form-button {
        padding: 22px 0px 0px 22px;
      }


.button-style {
  display: inline-block;
  color: #000;
  background: #e6b56a;
  background: -webkit-linear-gradient(right, #f5ca66, #e0ac6c);
  background: -moz-linear-gradient(right, #f5ca66, #e0ac6c);
  background: -ms-linear-gradient(right, #f5ca66, #e0ac6c);
  background: -o-linear-gradient(right, #f5ca66, #e0ac6c);
  background: linear-gradient(to right, #f5ca66, #e0ac6c);
  border: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  min-width: 10em;
  padding: 0.75em 1.2em;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  -webkit-transition: color .2s ease-in-out 0s;
  -moz-transition: color .2s ease-in-out 0s;
  -o-transition: color .2s ease-in-out 0s;
  transition: color .2s ease-in-out 0s;
}
  .button-style:hover,
  .button-style.button-blue {
    color: #fff;
  }

  .button-style.button-blue {
    background: #185580;
    background: -webkit-linear-gradient(right, #2473ab, #06263c);
    background: -moz-linear-gradient(right, #2473ab, #06263c);
    background: -ms-linear-gradient(right, #2473ab, #06263c);
    background: -o-linear-gradient(right, #2473ab, #06263c);
    background: linear-gradient(to right, #2473ab, #06263c);
  }

  .button-style:before {
    content: "";
    background: #fff;
    position: absolute;
    top: 0px;
    left: 50%;
    right: 50%;
    bottom: 0px;
    margin: 0px auto;
    opacity: 0.15;
    z-index: 1;

    -webkit-transform: skew(-45deg);
    -moz-transform: skew(-45deg);
    -ms-transform: skew(-45deg);
    -o-transform: skew(-45deg);
    transform: skew(-45deg);

    -webkit-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
    -moz-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
    -o-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
    transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
  }
    .button-style:hover:before {
      left: -1.5em;
      right: -1.5em;
    }

  .button-style * {
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
  }


ul.ul, ol.ol {
  margin-top: -6px;
  margin-bottom: 20px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 18px;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  ul.ul li, ol.ol li {
    padding-top: 6px;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li:before {
      content: "●";
      color: #e6b56a;
      display: inline-block;
      background-size: contain;
      font-weight: 500;
      margin-right: 0.7em;
      width: 0.45em;
    }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      font-weight: 500;
      color: #e6b56a;
    }


a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:before {
    content: "";
    background: url('/i/gallery-image-zoom.svg') 50% 50% no-repeat transparent;
    background-size: contain;
    position: absolute;
    top: 45%;
    left: 45%;
    width: 10%;
    height: 10%;
    opacity: 0;

    -webkit-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    -moz-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    -o-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  }
    a.zoom-image:hover:before {
      top: 37%;
      left: 37%;
      width: 26%;
      height: 26%;
      opacity: 0.9 !important;
    }



.unwrapper {
  margin-left: -9999px;
  margin-right: -9999px;
  position: static;
}
.wrapper {
  width: 1210px;
  margin: 0px auto;
  padding: 0px;
  position: relative;
}


/* Structure */
.structure {
  overflow: hidden;
}
  /* Header */
  .header {
    position: relative;
    z-index: 5;
  }
    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      padding: 15px 0px;
    }
      .header-logo-container {
        flex: 3 5 auto;
      }
        .header-logo-container > div {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }
          .header-slogan {
            font-size: 12px;
            padding-top: 5px;
          }


      .header-contacts {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 8 3 auto;
      }
        .header-contacts > div {
          padding-left: 15px;
        }
          .header-phones > div {
            padding-bottom: 4px;
          }
            .header-phones a {
              font-size: 20px;
              font-weight: 600;
              line-height: 1.2;
              text-decoration: none;
              white-space: nowrap;
              cursor: default;
            }
          .header-worktime {
            font-size: 12px;
          }

          .header-buttons {
            text-align: center;
          }
            .header-buttons > div {
              display: inline-flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: stretch;
            }
              .header-buttons .button-style {
                font-size: 12px;
                width: 100%;
              }
              .header-buttons .button-style + .button-style {
                margin-top: 4px;
              }


    .header-menu-container {
      background: #185580;
      background: -webkit-linear-gradient(left, #2473ab, #06263c);
      background: -moz-linear-gradient(left, #2473ab, #06263c);
      background: -ms-linear-gradient(left, #2473ab, #06263c);
      background: -o-linear-gradient(left, #2473ab, #06263c);
      background: linear-gradient(to left, #2473ab, #06263c);
    }
      .header-menu-button-container,
      .header-menu-checkbox {
        display: none;
      }

      .header-menu-container > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
      }
        .header-menu-container > ul > li {
          flex: 1 1 auto;
          position: relative;
        }
          .header-menu-container > ul > li.icn-menu-custom1 {
            display: none;
          }

          .header-menu-container > ul > li > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            position: relative;
          }
            .header-menu-container > ul > li > div:before {
              content: "";
              background: #e6b56a;
              background: -webkit-linear-gradient(right, #f5ca66, #e0ac6c);
              background: -moz-linear-gradient(right, #f5ca66, #e0ac6c);
              background: -ms-linear-gradient(right, #f5ca66, #e0ac6c);
              background: -o-linear-gradient(right, #f5ca66, #e0ac6c);
              background: linear-gradient(to right, #f5ca66, #e0ac6c);
              display: block;
              position: absolute;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 0;

              height: 100%;
              opacity: 0;

              -webkit-transition: opacity .2s ease-in-out 0s, height 0s ease-in-out .2s;
              -moz-transition: opacity .2s ease-in-out 0s, height 0s ease-in-out .2s;
              -o-transition: opacity .2s ease-in-out 0s, height 0s ease-in-out .2s;
              transition: opacity .2s ease-in-out 0s, height 0s ease-in-out .2s;
            }
              .header-menu-container > ul > li:hover > div:before {
                height: 4px;
                opacity: 1;

                -webkit-transition: opacity .2s ease-in-out 0s, height .2s ease-in-out 0s;
                -moz-transition: opacity .2s ease-in-out 0s, height .2s ease-in-out 0s;
                -o-transition: opacity .2s ease-in-out 0s, height .2s ease-in-out 0s;
                transition: opacity .2s ease-in-out 0s, height .2s ease-in-out 0s;
              }
              .header-menu-container > ul > li > div:hover:before {
                height: 100%;

                -webkit-transition: opacity .2s ease-in-out 0s, height .2s ease-in-out 0s;
                -moz-transition: opacity .2s ease-in-out 0s, height .2s ease-in-out 0s;
                -o-transition: opacity .2s ease-in-out 0s, height .2s ease-in-out 0s;
                transition: opacity .2s ease-in-out 0s, height .2s ease-in-out 0s;
              }
              .header-menu-container > ul > li.active > div:before {
                height: 100%;
                opacity: 1;
              }


            .header-menu-container > ul > li > div > div {
              position: relative;
              z-index: 1;
            }
              .header-menu-container > ul > li:not(.with-dropdown) > div > div {
                width: 100%;
              }

              .header-menu-container > ul > li > div a,
              .header-menu-container > ul > li > div > div > span {
                display: block;
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                white-space: nowrap;
                text-align: center;
                padding: 12px 6px;

                -webkit-transition: color .2s ease-in-out 0s;
                -moz-transition: color .2s ease-in-out 0s;
                -o-transition: color .2s ease-in-out 0s;
                transition: color .2s ease-in-out 0s;
              }
              .header-menu-container > ul li > div > div > span {
                cursor: default;
              }
              .header-menu-container > ul li > div a {
                cursor: pointer;
              }
                .header-menu-container > ul > li > div:hover div a,
                .header-menu-container > ul > li.active > div div a {
                  color: #000;
                }
                .header-menu-container > ul li.active > div a {
                  font-weight: 700;
                  cursor: default;
                }

                .header-menu-container > ul > li > div a > span,
                .header-menu-container > ul > li > div > div > span > span {
                  text-transform: uppercase;
                }

            .header-menu-container > ul > li > div > .dropmarker {
              color: transparent;
              font-size: 0px;
              width: 10px;
              min-width: 10px;
              cursor: default;
              position: relative;
              margin-right: 6px;
              box-sizing: content-box;
            }
              .header-menu-container > ul > li > div > .dropmarker:before {
                content: "";
                display: block;
                position: absolute;
                background: url('/i/header-menu-dropdown--white.svg') no-repeat 0px 50%/contain transparent;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: auto;
                -webkit-transition: background-image .2s ease-in-out 0s;
                -moz-transition: background-image .2s ease-in-out 0s;
                -o-transition: background-image .2s ease-in-out 0s;
                transition: background-image .2s ease-in-out 0s;
              }
                .header-menu-container > ul > li > div:hover > .dropmarker:before,
                .header-menu-container > ul > li.active > div > .dropmarker:before {
                  background-image: url('/i/header-menu-dropdown--black.svg');
                }

          .header-menu-container > ul > li > ul {
            background: #fff;
            border: 2px solid #e6b56a;
            position: absolute;
            top: 100%;
            left: 0px;
            min-width: 100%;
            padding: 15px 16px;
            box-shadow: 0px 3px 2px -1px rgba(0,0,0,.3);

            visibility: hidden;
            opacity: 0;

            -webkit-transition: opacity .2s ease 0s, visibility 0s ease .2s;
            -moz-transition: opacity .2s ease 0s, visibility 0s ease .2s;
            -o-transition: opacity .2s ease 0s, visibility 0s ease .2s;
            transition: opacity .2s ease 0s, visibility 0s ease .2s;
          }
            .header-menu-container > ul > li.with-groups > ul {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: stretch;
              padding: 15px 6px;
            }
            .header-menu-container > ul > li:hover > ul {
              visibility: visible;
              opacity: 1;

              -webkit-transition: opacity .2s ease 0s, visibility 0s ease 0s;
              -moz-transition: opacity .2s ease 0s, visibility 0s ease 0s;
              -o-transition: opacity .2s ease 0s, visibility 0s ease 0s;
              transition: opacity .2s ease 0s, visibility 0s ease 0s;
            }

            .header-menu-container > ul > li.with-groups > ul > li {
              padding: 0px 20px 0px 10px;
            }
              .header-menu-container > ul > li.with-groups > ul > li + li {
                border-left: 1px solid #eaeaea;
              }

              .header-menu-container > ul > li > ul > li:not(.icn-menu-service-group-null) > ul {
                padding-left: 12px;
              }
                .header-menu-container > ul > li > ul > li.icn-menu-service-group-null > div,
                .header-menu-container > ul > li ul > li > div > .dropmarker {
                  display: none;
                }

                .header-menu-container > ul > li ul > li > div a,
                .header-menu-container > ul > li ul > li > div > div > span {
                  display: block;
                  font-size: 14px;
                  font-weight: 500;
                  padding: 6px 12px;
                  white-space: nowrap;
                  position: relative;
                }
                  .header-menu-container > ul > li ul > li > div a {
                    cursor: pointer;

                    -webkit-transition: background .2s ease-in-out 0s;
                    -moz-transition: background .2s ease-in-out 0s;
                    -o-transition: background .2s ease-in-out 0s;
                    transition: background .2s ease-in-out 0s;
                  }
                    .header-menu-container > ul > li ul > li > div a:before {
                      content: "";
                      background: #e6b56a;
                      background: -webkit-linear-gradient(right, #f5ca66, #e0ac6c);
                      background: -moz-linear-gradient(right, #f5ca66, #e0ac6c);
                      background: -ms-linear-gradient(right, #f5ca66, #e0ac6c);
                      background: -o-linear-gradient(right, #f5ca66, #e0ac6c);
                      background: linear-gradient(to right, #f5ca66, #e0ac6c);
                      display: block;
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      right: 0px;
                      bottom: 0px;
                      z-index: 0;
                      opacity: 0;

                      -webkit-transition: opacity .2s ease-in-out 0s;
                      -moz-transition: opacity .2s ease-in-out 0s;
                      -o-transition: opacity .2s ease-in-out 0s;
                      transition: opacity .2s ease-in-out 0s;
                    }
                      .header-menu-container > ul > li ul > li > div a:hover:before,
                      .header-menu-container > ul > li ul > li.active > div a:before {
                        opacity: 1;
                      }

                    .header-menu-container > ul > li ul > li > div a > span {
                      position: relative;
                      z-index: 1;
                    }

                .header-menu-container > ul > li.with-groups > ul > li > div a,
                .header-menu-container > ul > li.with-groups > ul > li > div > div > span {
                  font-size: 16px;
                  font-weight: 700;
                }


  /* Content block */
  .content {
    position: relative;
    overflow: hidden;
    z-index: 3;

    /*min-height: 3000px;*/
  }
    .main-rotator-container {
      position: relative;
      margin: 15px 0px 10px;
    }
      .main-rotator-container > .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
      }
        #main-rotator {
          flex: 1 2 100%;
          order: 1;
          height: 400px;
          position: relative;
          overflow: hidden;
        }
          .main-rotator-slide-container {
            position: relative;
          }
            #main-rotator > .main-rotator-slide-container {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 2;
            }
              #main-rotator > .main-rotator-slide-container + .main-rotator-slide-container {
                z-index: 1;
              }

            .main-rotator-slide-image {
              position: relative;
              background: no-repeat 50% 50%/cover #eee;
              height: 400px;
            }
              .main-rotator-slide-image a {
                content: "";
                display: block;
                font-size: 0px;
                cursor: pointer;
                height: 100%;
                position: relative;
                z-index: 1;
              }

            .main-slide-code-container {
              height: 0px;
              position: absolute;
              top: 0px;
              left: 62px;
              right: 62px;
            }

          #main-rotator-controls {
            height: 0px;
            position: relative;
            z-index: 2;
          }
            #main-rotator-controls .slick-prev,
            #main-rotator-controls .slick-next {
              display: inline-block;
              font-size: 0px;
              background: url('/i/arrow-left--white.svg') no-repeat 50% 50%/auto 34px transparent;
              width: 62px;
              height: 400px;
              cursor: pointer;
              position: absolute;
              top: 0px;
            }
            #main-rotator-controls .slick-prev {
              left: 0px;
            }
            #main-rotator-controls .slick-next {
              background-image: url('/i/arrow-right--white.svg');
              right: 0px;
            }


        .main-rotator-mini-slides {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          flex: 1 5 100%;
          order: 2;
          margin: -15px 0px 0px 15px;
          position: relative;
          overflow: hidden;
        }
          .main-rotator-mini-slides > div {
            flex: 1 1 auto;
            margin: 15px 0px 0px;
            position: relative;
          }
            .main-rotator-mini-image {
              background: no-repeat 50% 50%/cover #eee;
              height: 100%;
              position: relative;
            }
              .main-rotator-mini-image a {
                color: transparent;
                font-size: 0px;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
              }

            .main-rotator-mini-code {
              position: absolute;
              left: 15px;
              right: 15px;
              bottom: 10px;
            }


    .breadcrumbs {
      font-size: 0px;
      background: #f0f0f0;
      padding: 7px 10px;
      margin: 15px 0px 0px;
    }
      .breadcrumbs li {
        display: inline;
        font-size: 0px;
      }
        .breadcrumbs li:before,
        .breadcrumbs a,
        .breadcrumbs span {
          font-size: 13px;
          vertical-align: baseline;
          line-height: 1.2;
        }
        .breadcrumbs li + li:before {
          content: "\232A";
          display: inline-block;
          background: url('/i/arrow-right--black.svg') no-repeat 50% 50%/auto 10px transparent;
          width: 20px;
          color: transparent;
          font-weight: 600;
        }
        .breadcrumbs span {
          color: #333;
          font-weight: 600;
        }
        .breadcrumbs a {
          cursor: pointer;
          text-decoration: underline;
        }
          .breadcrumbs a:hover {
            color: #000;
            text-decoration: none;
          }


    .subheader,
    .subsubheader,
    .subsubsubheader {
      position: relative;
      font-size: 24px;
      font-weight: 500;
      padding: 20px 0px 10px;
      margin: 0px 0px 20px;
      text-align: left;
      z-index: 2;
    }
      .subheader:after {
        content: "";
        background: #185580;
        background: -webkit-linear-gradient(left, #2473ab, #06263c);
        background: -moz-linear-gradient(left, #2473ab, #06263c);
        background: -ms-linear-gradient(left, #2473ab, #06263c);
        background: -o-linear-gradient(left, #2473ab, #06263c);
        background: linear-gradient(to left, #2473ab, #06263c);
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100px;
        height: 4px;
        margin: auto;
      }

      h1.subheader {
        font-weight: 600;
      }
        h1.subheader:after {
          height: 5px;
        }

      .quick-link-list + .subheader {
        padding-top: 0px;
      }

    .subsubheader {
      font-size: 20px;
      font-weight: 600;
      padding: 0px 0px 15px;
      margin: 0px;
    }

    .subsubsubheader {
      font-size: 16px;
      padding: 0px 0px 8px;
      margin: 0px;
    }

      .subheader,
      .subsubheader,
      .subsubsubheader,
      .subheader > *,
      .subsubheader > *,
      .subsubsubheader > * {
        text-transform: uppercase;
      }


    .text-container {
      padding: 0px 0px 20px;
      position: relative;
      z-index: 1;
    }


    .quick-link-list {
      background: #f0f0f0;
      padding: 0px 12px 10px 0px;
      margin: 0px 0px 20px;
      position: relative;
      z-index: 1;
    }
      .quick-link-list > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: center;
      }
        .quick-link-list > div > div {
          padding: 10px 0px 0px 12px;
        }
          .quick-link-list a {
            background: #e6b56a;
            background: -webkit-linear-gradient(right, #f5ca66, #e0ac6c);
            background: -moz-linear-gradient(right, #f5ca66, #e0ac6c);
            background: -ms-linear-gradient(right, #f5ca66, #e0ac6c);
            background: -o-linear-gradient(right, #f5ca66, #e0ac6c);
            background: linear-gradient(to right, #f5ca66, #e0ac6c);

            display: block;
            font-size: 12px;
            font-weight: 500;
            padding: 8px 12px;
            cursor: pointer;
            position: relative;

            -webkit-transition: color .2s ease-in-out 0s;
            -moz-transition: color .2s ease-in-out 0s;
            -o-transition: color .2s ease-in-out 0s;
            transition: color .2s ease-in-out 0s;
          }
            .quick-link-list a:hover,
            .quick-link-list a.active {
              color: #fff;
            }
            .quick-link-list a.active {
              cursor: default;
            }

            .quick-link-list a:before {
              content: "";
              background: #f0f0f0;
              position: absolute;
              top: 2px;
              left: 2px;
              right: 2px;
              bottom: 2px;
              opacity: 1;
              z-index: 0;

              -webkit-transition: opacity .2s ease-in-out 0s;
              -moz-transition: opacity .2s ease-in-out 0s;
              -o-transition: opacity .2s ease-in-out 0s;
              transition: opacity .2s ease-in-out 0s;
            }
              .quick-link-list a:hover:before,
              .quick-link-list a.active:before {
                opacity: 0;
              }

            .quick-link-list a > span {
              position: relative;
              z-index: 1;
            }


    .price-groupped-table-container .quick-link-list {
      background: none;
      padding: 0px;
    }
      .price-groupped-table-container .quick-link-list > div {
        justify-content: center;
        margin: 0px -10px;
      }
        .price-groupped-table-container .quick-link-list > div > div {
          font-size: 0px;
          border-right: 1px solid #111;
          padding: 4px 10px;
        }
          .price-groupped-table-container .quick-link-list > div > div:last-child {
            border-right-color: transparent;
          }

          .price-groupped-table-container .quick-link-list a {
            font-size: 15px;
            font-weight: 400;
            display: inline;
            color: #000;
            background: none !important;
            border: 0px dashed #000;
            border-bottom-width: 1px;
            line-height: 1;
            padding: 0px;

            -webkit-transition: color .2s ease-in-out 0s, border-color .2s ease-in-out 0s;
            -moz-transition: color .2s ease-in-out 0s, border-color .2s ease-in-out 0s;
            -o-transition: color .2s ease-in-out 0s, border-color .2s ease-in-out 0s;
            transition: color .2s ease-in-out 0s, border-color .2s ease-in-out 0s;
          }
            .price-groupped-table-container .quick-link-list a:hover {
              border-bottom-color: transparent;
            }
            .price-groupped-table-container .quick-link-list:not(.selected) > div > div:first-child a,
            .price-groupped-table-container .quick-link-list > div > div.active a {
              color: #1e6496;
              border-bottom-color: transparent;
              font-weight: 700;
              cursor: default;
            }

            .price-groupped-table-container .quick-link-list a:before {
              display: none;
            }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      color: #1e6496;
      font-weight: 500;
      font-style: italic;
      padding: 0px 0px 20px;
      margin: -14px 0px 0px -14px;
      position: relative;
    }
      .gallery-item {
        padding: 14px 0px 0px 14px;
        position: relative;
      }

      .gallery-notice {
        text-align: center;
        margin: -8px 0px 0px !important;
      }


    .list-view,
    .portfolio-items {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: stretch;
      position: relative;
    }
    .list-view {
      margin: -15px 0px 0px -10px;
      padding: 0px 0px 20px;
    }
      .list-view-item {
        width: 25%;
        padding: 15px 0px 0px 10px;
        position: relative;
      }
        .list-view-item-wrapper {
          background: #f0f0f0;
          height: 100%;
          position: relative;
        }
          .list-view-item-image {
            font-size: 0px;
            padding: 4px 4px 0px;
            position: relative;
            overflow: hidden;
            z-index: 1;
          }
            .list-view-item-image > a,
            .list-view-item-image > span {
              display: block;
              background: no-repeat 50% 50%/cover transparent;
              height: 0px;
              padding-top: 62%;
            }
              .list-view-item-image > a {
                cursor: pointer;
              }

          .list-view-item-content {
            padding: 14px 15px 15px;
            position: relative;
            z-index: 2;
          }
            .list-view-item-name {
              position: relative;
            }
              .list-view-item-name a,
              .list-view-item-name span {
                font-weight: 500;
                text-transform: uppercase;
                text-decoration: none;
              }
              .list-view-item-name a {
                color: #1e6496;
                text-decoration: underline;
                cursor: pointer;
              }
                .list-view-item-image:hover + .list-view-item-content .list-view-item-name a,
                .list-view-item-name a:hover {
                  text-decoration: none;
                }

          .list-view-item-notice {
            font-size: 14px;
            padding: 10px 0px 0px;
          }
            .list-view-item-notice *:last-child {
              margin-bottom: 0px;
            }


    .list-block-container + .list-page-group-name {
      padding-top: 10px;
    }
    .list-page-group-name ~ .list-block-container + .list-block-container {
      padding-top: 20px;
    }


    .gallery-rotator-block-container .list-view-item {
      width: 50%;
    }
    .gallery-rotator-block-container.gallery-items-width-3 .list-view-item,
    .gallery-mini-block-container .list-view-item {
      width: 33.333333%;
    }
    .gallery-rotator-block-container.gallery-items-width-4 .list-view-item {
      width: 25%;
    }
      .gallery-rotator {
        position: relative;
      }
        .gallery-rotator:before {
          content: "";
          background: #eee;
          display: block;
          height: 0px;
          padding: 60% 0% 0%;
        }
          .gallery-rotator.slick-initialized:before {
            display: none;
          }

        .gallery-rotator-image {
          position: relative;
          z-index: 0;
        }
          .gallery-rotator > .gallery-rotator-image {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
          }
            .gallery-rotator > .gallery-rotator-image:first-child {
              z-index: 1;
            }

            .gallery-rotator-image a,
            .gallery-rotator-image img {
              width: 100%;
            }

        .gallery-rotator-pager {
          position: relative;
          overflow: hidden;
        }
          .gallery-rotator-pager > div {
            display: none;
            margin: 0px 0px 0px -6px;
          }
            .gallery-rotator-pager > div.slick-initialized {
              display: block;
            }

            .gallery-rotator-pager .slick-track {
              margin: 0px;
            }

            .gallery-rotator-pager-image {
              display: inline-block;
              width: 16.666666%;
              padding: 6px 0px 0px 6px;
              z-index: 0;
            }
              .gallery-rotator-pager-image div {
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                height: 0px;
                padding: 60% 0% 0%;
                cursor: pointer;
                position: relative;
              }
                .gallery-rotator-pager-image div:after {
                  content: "";
                  border: 2px solid transparent;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  z-index: 0;

                  -webkit-transition: border-color .3s ease 0s;
                  -moz-transition: border-color .3s ease 0s;
                  -o-transition: border-color .3s ease 0s;
                  transition: border-color .3s ease 0s;
                }
                  .gallery-rotator-pager-image.slick-current div:after {
                    border-color: #185580;
                  }

      .gallery-rotator-block-container .list-view-item-name span {
        font-size: 18px;
      }
        .gallery-rotator-block-container.gallery-items-width-3 .list-view-item-name span,
        .gallery-rotator-block-container.gallery-items-width-4 .list-view-item-name span {
          font-size: 16px;
        }


    .gallery-mini-block-container .list-view-item-image {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: flex-start;
      align-items: flex-start;
      padding: 0px 4px 0px 0px;
    }
      .gallery-mini-block-container .list-view-item-image > span {
        background: none;
        height: auto;
        padding: 4px 0px 0px 4px;
      }
        .gallery-mini-block-container .list-view-item-image > span:first-child {
          width: 100%;
        }
        .gallery-mini-block-container .list-view-item-image > span + span {
          width: 33.333333%;
        }
        .gallery-mini-block-container .list-view-item-image > span:nth-child(n + 5) {
          display: none;
        }

        .gallery-mini-block-container .list-view-item-image a {
          display: block;
        }
          .gallery-mini-block-container .list-view-item-image > span + span a.zoom-image:hover:before {
            top: 20%;
            left: 20%;
            width: 60%;
            height: 60%;
          }

        .gallery-mini-block-container .list-view-item-image img {
          width: 100%;
        }


    .service-block-container .list-view {
      justify-content: flex-start;
    }
      .service-block-container .list-view-item {
        width: 33.333333%;
      }
        .service-block-container .list-view-item-wrapper {
          min-height: 185px;
          z-index: 1;
        }
          .service-block-container .list-view-item-image {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            padding: 0px;
            margin: 0px;
          }
            .service-block-container .list-view-item-image > span {
              width: 70%;
              height: 100%;
              padding-top: 0px;
              margin: 0px 0px 0px auto;
              position: relative;
              z-index: 0;
            }

            .service-block-container .list-view-item-image:after {
              content: "";
              background: repeat-y top right/100% auto transparent;
              display: block;
              position: absolute;
              top: 0px;
              left: 0px;
              bottom: 0px;
              width: 60%;
              z-index: 1;
            }
              .service-block-container .list-view-item:nth-child(8n+1) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-01.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+2) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-02.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+3) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-03.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+4) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-04.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+5) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-05.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+6) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-06.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+7) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-07.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+8) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-08.svg');
              }


          .service-block-container .list-view-item-content {
            height: 100%;
            padding: 25px 35% 20px 22px;
            text-shadow: 1px 1px 2px #fff;
          }
            .service-block-container .list-view-item-name a,
            .service-block-container .list-view-item-name span {
              color: #000;
              font-size: 18px;
            }
              .service-block-container .list-view-item-name a > span,
              .service-block-container .list-view-item-name span > span {
                font-size: 20px;
                font-weight: 600;
              }


            .service-block-container .list-view-item-notice {
              font-size: 16px;
              font-weight: 400;
              padding-top: 15px;
            }


    .price-vitrine-item-price {
      color: #ec5b47;
      line-height: 1.2;
      font-weight: 700;
      padding: 10px 0px 0px;
    }
      .price-vitrine-item-price > span {
        font-size: inherit;
        width: 0px;
        height: 0px;
        padding: 0px 3px 0px;
      }


    .portfolio-block-container .list-view-item-name {
      min-height: 48px;
    }


    .portfolio-page-block-container .list-view {
      overflow: hidden;
    }
      .portfolio-items {
        flex-grow: 1;
      }
        .portfolio-page-block-container .portfolio-items {
          justify-content: flex-start;
        }


  .advantages-block-container .list-view-item {
    width: 25%;
  }
    .advantages-block-container .list-view-item-wrapper {
      background: #185580;
      background: -webkit-linear-gradient(right, #2473ab, #06263c);
      background: -moz-linear-gradient(right, #2473ab, #06263c);
      background: -ms-linear-gradient(right, #2473ab, #06263c);
      background: -o-linear-gradient(right, #2473ab, #06263c);
      background: linear-gradient(to right, #2473ab, #06263c);
      min-height: 151px;
      padding: 3px;
    }
    .advantages-block-container .list-view-item-wrapper:before {
      content: "";
      display: block;
      background: #fafafb;
      position: absolute;
      top: 3px;
      left: 3px;
      right: 118px;
      bottom: 3px;
      z-index: 0;
    }
    .advantages-block-container .list-view-item-wrapper:after {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      right: 3px;
      bottom: 3px;
      border-bottom: 145px solid transparent;
      border-left: 115px solid #fafafb;
      z-index: 0;
    }

    .advantages-block-container .list-view-item-image {
      position: absolute;
      right: 12px;
      bottom: 15px;
      width: 50px;
      height: 50px;
      padding: 0px;
      z-index: 1;
    }
      .advantages-block-container .list-view-item-image span {
        background: no-repeat 50% 50%/cover transparent;
        padding: 100% 0% 0%;
      }

    .advantages-block-container .list-view-item-content {
      line-height: 1.3;
      padding: 18px 20px 20px;
      z-index: 2;
    }
      .advantages-block-container .list-view-item-name {
        padding-right: 20px;
      }
        .advantages-block-container .list-view-item-name span {
          font-weight: 500;
        }

      .advantages-block-container .list-view-item-notice {
        padding-right: 60px;
      }


    .price-table {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 0px 0px 20px;
      position: relative;
    }
      .price-table-counter {
        counter-reset: price-table-group-counter;
      }
      .price-table > div {
        border-bottom: 1px solid #e8e8e8;
      }
        .price-groupped-table-container .price-table:not(.selected) > div:not(:first-child),
        .price-groupped-table-container .price-table.selected > div:not(.active),
        .price-groupped-table-container .price-table-group {
          display: none;
        }

      .price-table > div + div {
        margin-top: 35px;
      }
        .price-groupped-table-container .price-table > div + div {
          margin-top: 0px;
        }

        .price-table > div > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
        }
        .price-table-group {
          justify-content: flex-start !important;
          padding: 0px 0px 14px 30px;
        }
          .price-table-counter .price-table-group {
            counter-reset: price-table-subheader-counter;
          }
          .price-table-group span {
            color: #1e6496;
            font-size: 22px;
            font-weight: 700;
            text-transform: uppercase;
          }
            .price-table-counter .price-table-group span:before {
              content: counter(price-table-group-counter) ". ";
              counter-increment: price-table-group-counter;
            }

        .price-table-head {
          color: #fff;
          background: #185580;
          background: -webkit-linear-gradient(left, #2473ab, #06263c);
          background: -moz-linear-gradient(left, #2473ab, #06263c);
          background: -ms-linear-gradient(left, #2473ab, #06263c);
          background: -o-linear-gradient(left, #2473ab, #06263c);
          background: linear-gradient(to left, #2473ab, #06263c);
          align-items: flex-end !important;
        }

        .price-table-subheader {
          color: #fff;
          background: #185580;
          background: -webkit-linear-gradient(right, #4487b7, #fff);
          background: -moz-linear-gradient(right, #4487b7, #fff);
          background: -ms-linear-gradient(right, #4487b7, #fff);
          background: -o-linear-gradient(right, #4487b7, #fff);
          background: linear-gradient(to right, #4487b7, #fff);
          position: relative;
          z-index: 1;
        }

        .price-table-row {
          background: #fff;
        }
          .price-table-row.odd,
          .price-table-design .price-table-row:nth-child(odd) {
            background: #f0f0f0;
          }
          .price-name,
          .price-price,
          .price-unit {
            font-size: 15px;
            font-weight: 400;
            padding: 5px 2px;
            flex-basis: 100%;
            flex-shrink: 4;
          }
          .price-name {
            padding-left: 30px;
          }
          .price-price,
          .price-unit {
            text-align: center;
            flex-shrink: 8;
          }
            .price-price > span {
              font-size: inherit;
              width: 0px;
              height: 0px;
              padding: 0px 2px 0px;
            }

            .price-table-head .price-name,
            .price-table-head .price-price,
            .price-table-head .price-unit {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.2;
              text-transform: uppercase;
              padding-top: 11px;
              padding-bottom: 10px;
            }
            .price-table-subheader .price-name {
              /*color: #1e6496;*/
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              padding-top: 10px;
              padding-bottom: 8px;
              /*border-bottom: 1px solid #e8e8e8;*/
            }
              .price-table-counter .price-table-subheader .price-name:before {
                content: counter(price-table-group-counter) "." counter(price-table-subheader-counter) ". ";
                counter-increment: price-table-subheader-counter;
                font-weight: 500;
              }
              /*.price-table-row + .price-table-subheader .price-name {
                border-top: 1px solid #e8e8e8;
              }*/


    .portfolio-info-icons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: center;
      margin: -8px 0px 0px -15px;
    }
      .portfolio-block-container .portfolio-info-icons {
        font-size: 14px;
      }

      .portfolio-info-icons > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        padding: 8px 0px 0px 15px;
      }
        .portfolio-info-icons img {
          height: 18px;
          margin-right: 8px;
        }

        .portfolio-info-icons .price-item > span,
        .portfolio-info-icons .price-stuff > span + span {
          line-height: 1;
          border-bottom: 1px dashed #000;
          cursor: pointer;
        }
          .portfolio-info-icons .price-item > span:hover,
          .portfolio-info-icons .price-stuff > span + span:hover {
            border-bottom-color: transparent;
          }

          .portfolio-info-icons .price-item > span > span,
          .portfolio-info-icons .price-stuff > span > span {
            font-size: inherit;
            width: 0px;
            height: 0px;
            padding: 0px 2px 0px;
          }

        .portfolio-info-icons .price-stuff > span:first-child {
          color: #e6b56a;
          font-size: 14px;
          font-weight: 600;
          margin-right: 5px;
        }

      .portfolio-info-icons + div {
        padding: 18px 0px 0px;
      }


    .product-list-full {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;
      position: relative;
      margin: 0px 0px 0px -10px;
    }
      #product-filters {
        width: 25%;
        min-width: 25%;
        padding: 0px 10px 15px;
        position: relative;
      }
        .selected-filters-container {
          font-size: 14px;
          background: #eaeaea;
          border: 2px solid #ccc;
          line-height: 1.1;
          margin: 0px 0px 20px;
        }
          .selected-filters-container > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
            padding: 15px;
          }
            .selected-filters-container > div:first-child span {
              font-weight: 500;
            }
            .selected-filters-container > div:first-child a {
              font-size: 12px;
              color: #505050;
              border-bottom: 1px dashed #505050;
              cursor: pointer;
            }
              .selected-filters-container > div:first-child a:hover {
                border-bottom-color: transparent;
              }

          .selected-filters-container > div + div {
            padding: 0px 15px;
          }
            .selected-filters-container > div + div > div {
              border-top: 2px solid #ccc;
              padding: 15px 0px;
            }
              .selected-filters-container > div + div > div > div {
                font-size: 12px;
              }
              .selected-filters-container > div + div > div > div:first-child {
                font-weight: 500;
                padding: 0px 0px 10px;
              }

              .selected-filters-container > div + div > div > div + div {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-content: flex-start;
                align-items: center;
                margin: -6px 0px 0px -6px;
              }
                .selected-filters-container > div + div > div > div + div a {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  background: #fff;
                  border-radius: 2px;
                  padding: 5px 8px 5px 10px;
                  margin: 6px 0px 0px 6px;
                  cursor: pointer;
                }
                  .selected-filters-container > div + div > div > div + div a:after {
                    content: "\00d7";
                    font-size: 16px;
                    color: #e04949;
                    margin: 0px 0px 0px 6px;
                  }


        .filter-container {
          font-size: 14px;
          border: 2px solid #f0f0f0;
        }
          .filter-elements-container > div {
            border-bottom: 2px solid #f0f0f0;
          }
            .filter-dropdown-button {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              font-weight: 500;
              line-height: 1.1;
              padding: 15px;
              position: relative;
              z-index: 1;
            }
              .filter-dropdown-button.is-selected {
                border: 2px solid #e6b56a;
                border-bottom: none;
                margin: -2px -2px 0px;
              }

          .filter-dropdown-container {
            padding: 0px 15px 16px;
            position: relative;
          }
            .filter-dropdown-button.is-selected + .filter-dropdown-container {
              border: 2px solid #e6b56a;
              border-top: none;
              margin: 0px -2px -2px;
            }

            .filter-checkbox-list {
              margin-top: -6px;
            }
              .filter-checkbox-field {
                margin-top: 6px;
              }
                .filter-checkbox-field > input { display: none; }

                .filter-checkbox-field > label {
                  display: inline-flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  cursor: pointer;
                }
                  .filter-checkbox-field > input.disabled + label {
                    color: #909090;
                    cursor: default;
                  }

                  .filter-checkbox-field > label > span {
                    background: #fff;
                    border: 1px solid #909090;
                    width: 14px;
                    min-width: 14px;
                    height: 14px;
                    margin-right: 12px;
                    position: relative;
                  }
                    .filter-checkbox-field > input:hover + label > span,
                    .filter-checkbox-field > input:checked + label > span {
                      background-color: #f5ca66;
                      border-color: #dca86c !important;
                    }
                      .filter-checkbox-field > input:checked + label > span:before {
                        content: "●";
                        color: transparent;
                        display: block;
                        background: url('/i/filter-checkbox-icon--black.svg') no-repeat 50% 50%/contain transparent;
                        width: 10px;
                        height: 10px;
                        margin: auto;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                      }

                    .filter-checkbox-field > input.disabled + label > span {
                      background-color: #f4f4f5;
                      border-color: #e7e7e7 !important;
                    }
                      .filter-checkbox-field > input.disabled + label > span:before {
                        background-image: url('/i/filter-checkbox-icon--gray.svg');
                      }

            .filter-slider-inputs {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              margin-left: -20px;
            }
              .filter-slider-inputs > label { display: none; }

              .filter-slider-inputs > div {
                width: 50%;
                padding-left: 20px;
              }
                .filter-slider-inputs .input-container {
                  border: 1px solid #979797;
                  padding: 6px 12px 5px;
                }
                  .filter-slider-inputs .input-container input {
                    line-height: 1;
                  }

            .filter-slider-container {
              display: none;
              padding: 18px 0px 0px;
              position: relative;
            }
              .filter-slider-container.is-created {
                display: block;
              }

              .filter-slider-container > div {
                background: #ccc;
                border-radius: 999px;
                height: 3px;
                margin: 1px 4px;
                position: relative;
              }
                .filter-slider-container > div > div {
                  background: #e6b56a;
                  position: absolute;
                  top: -1px;
                  bottom: -1px;
                }

                .filter-slider-container > div > span {
                  display: block;
                  background: #f5ca66;
                  border: 2px solid #dca86c;
                  border-radius: 50%;
                  position: absolute;
                  top: -4px;
                  bottom: -4px;
                  width: 11px;
                  margin-left: -5px;
                  cursor: pointer;
                }
                  .filter-slider-container > div > span > span { display: none; }


          .filter-buttons-container {
            line-height: 1.1;
            padding: 15px;
          }
            .filter-buttons-container > div {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }
              .filter-buttons-container > div > div {
                text-align: center;
                width: 50%;
              }
                .filter-buttons-container .filter-button-submit .button-style {
                  min-width: auto;
                  width: 100%;
                  padding: 10px;
                }

                .filter-buttons-container .filter-button-reset a {
                  display: inline-block;
                  color: #505050;
                  border-bottom: 1px dashed #505050;
                  cursor: pointer;
                }
                  .filter-buttons-container .filter-button-reset a:hover {
                    border-bottom-color: transparent;
                  }


      #product-list {
        width: 100%;
        padding: 0px 0px 0px 10px;
      }
        .pager-block-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          background: #f0f0f0;
          line-height: 1.2;
          padding: 10px 12px;
          margin: 0px 0px 15px;
          position: relative;
          z-index: 2;
        }
          .pagesize-container,
          .pager-block-container ul.pager {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
            .pagesize-container .input-container {
              background: #e6b56a;
              background: -webkit-linear-gradient(right, #f5ca66, #e0ac6c);
              background: -moz-linear-gradient(right, #f5ca66, #e0ac6c);
              background: -ms-linear-gradient(right, #f5ca66, #e0ac6c);
              background: -o-linear-gradient(right, #f5ca66, #e0ac6c);
              background: linear-gradient(to right, #f5ca66, #e0ac6c);
              border: none;
              padding: 2px;
              margin: 0px 0px 0px 8px;
            }
              .pager-block-container .pagesize-container .input-container select {
                background: url('/i/header-menu-dropdown--black.svg') no-repeat right 6px center/10px auto #fff;
                display: inline-block;
                line-height: 1;
                width: auto;
                height: 24px;
                padding: 2px 20px 2px 8px;
                cursor: pointer;
              }

          .pager-block-container ul.pager {
            align-items: stretch;
          }
            .pager-block-container ul.pager > li {
              height: 28px;
              margin: 0px 0px 0px 8px;
            }
              .pager-block-container ul.pager > li.previous > div,
              .pager-block-container ul.pager > li.next > div {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
              }
                .pager-block-container ul.pager > li.previous > div > a,
                .pager-block-container ul.pager > li.next > div > a {
                  color: transparent;
                  font-size: 0px;
                  border-top: 10px solid transparent;
                  border-bottom: 10px solid transparent;
                  width: 0px;
                  height: 0px;
                  cursor: pointer;
                }
                .pager-block-container ul.pager > li.previous > div > a {
                  border-right: 16px solid #1e6496;
                }
                  .pager-block-container ul.pager > li.previous.hidden > div > a {
                    border-right-color: #ccc;
                    cursor: default;
                  }

                .pager-block-container ul.pager > li.next > div > a {
                  border-left: 16px solid #1e6496;
                }
                  .pager-block-container ul.pager > li.next.hidden > div > a {
                    border-left-color: #ccc;
                    cursor: default;
                  }

              .pager-block-container ul.pager > li.first,
              .pager-block-container ul.pager > li.last {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: baseline;
              }
                .pager-block-container ul.pager > li.first:after,
                .pager-block-container ul.pager > li.last:before {
                  content: "\2026";
                }
                .pager-block-container ul.pager > li.first:after {
                  margin: 0px 0px 0px 8px;
                }
                .pager-block-container ul.pager > li.last:before {
                  margin: 0px 8px 0px 0px;
                }

                .pager-block-container ul.pager > li.first > div,
                .pager-block-container ul.pager > li.last > div,
                .pager-block-container ul.pager > li.page > div {
                  background: #e6b56a;
                  background: -webkit-linear-gradient(right, #f5ca66, #e0ac6c);
                  background: -moz-linear-gradient(right, #f5ca66, #e0ac6c);
                  background: -ms-linear-gradient(right, #f5ca66, #e0ac6c);
                  background: -o-linear-gradient(right, #f5ca66, #e0ac6c);
                  background: linear-gradient(to right, #f5ca66, #e0ac6c);
                  display: inline-flex;
                  height: 100%;
                  padding: 2px;
                }
                  .pager-block-container ul.pager > li.first > div > a,
                  .pager-block-container ul.pager > li.last > div > a,
                  .pager-block-container ul.pager > li.page > div > a {
                    background: #fff;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 32px;
                    height: 100%;
                    padding: 2px 8px;
                    cursor: pointer;
                    position: relative;
                    -webkit-transition: color .2s ease 0s, background-color .2s ease 0s;
                    -moz-transition: color .2s ease 0s, background-color .2s ease 0s;
                    -o-transition: color .2s ease 0s, background-color .2s ease 0s;
                    transition: color .2s ease 0s, background-color .2s ease 0s;
                  }
                    .pager-block-container ul.pager > li.first > div > a:hover,
                    .pager-block-container ul.pager > li.last > div > a:hover,
                    .pager-block-container ul.pager > li.page > div > a:hover,
                    .pager-block-container ul.pager > li.active > div > a {
                      color: #fff;
                      background: transparent;
                    }


        .product-list-container .list-view {
          justify-content: flex-start;
        }
          #product-filters + #product-list .list-view-item {
            width: 33.33333333%;
          }
            .product-list-container .list-view-item:hover {
              z-index: 3;
            }

            .product-list-container .list-view-item-wrapper,
            .product-view-item-buttons {
              background: #185580;
              background: -webkit-linear-gradient(right, #2473ab, #06263c);
              background: -moz-linear-gradient(right, #2473ab, #06263c);
              background: -ms-linear-gradient(right, #2473ab, #06263c);
              background: -o-linear-gradient(right, #2473ab, #06263c);
              background: linear-gradient(to right, #2473ab, #06263c);
            }
            .product-list-container .list-view-item-wrapper {
              padding: 4px;
            }
              .product-list-container .list-view-item-wrapper > div {
                background: #f0f0f0;
                height: 100%;
                position: relative;
              }
                .product-list-container .list-view-item-image {
                  padding: 0px;
                }
                  .product-list-container .list-view-item-image > a {
                    padding-top: 66.20209059%;
                  }

                .product-list-container .list-view-item-content {
                  padding: 10px 11px 11px;
                }
                  .product-list-container .list-view-item-name a {
                    font-weight: 600;
                  }


                  .product-list-container .list-view-item-notice {
                    white-space: nowrap;
                    line-height: 1;
                    padding: 4px 0px 4px;
                  }
                    .product-list-container .portfolio-info-icons {
                      margin-top: 0;
                    }

                    .product-list-container .product-view-item-price {
                      font-size: 16px;
                      padding: 12px 0px 0px;
                    }
                      .product-list-container .product-view-item-price > div {
                        vertical-align: baseline;
                      }
                        .product-list-container .product-view-item-price .price span {
                          font-size: 18px;
                          font-weight: 600;
                          vertical-align: baseline;
                        }
                        .product-list-container .product-view-item-price .price .price-label {
                          font-size: 14px;
                          font-weight: 400;
                        }


                  .product-view-item-buttons {
                    position: absolute;
                    top: 100%;
                    left: -4px;
                    right: -4px;
                    padding: 0px 4px 4px;

                    visibility: hidden;
                    opacity: 0;

                    -webkit-transition: visibility 0s ease .2s, opacity .2s ease 0s;
                    -moz-transition: visibility 0s ease .2s, opacity .2s ease 0s;
                    -o-transition: visibility 0s ease .2s, opacity .2s ease 0s;
                    transition: visibility 0s ease .2s, opacity .2s ease 0s;
                  }
                    .product-list-container .list-view-item-wrapper:hover .product-view-item-buttons {
                      visibility: visible;
                      opacity: 1;

                      -webkit-transition: visibility 0s ease 0s, opacity .2s ease 0s;
                      -moz-transition: visibility 0s ease 0s, opacity .2s ease 0s;
                      -o-transition: visibility 0s ease 0s, opacity .2s ease 0s;
                      transition: visibility 0s ease 0s, opacity .2s ease 0s;
                    }

                    .product-view-item-buttons .product-buttons-container {
                      background: #f0f0f0;
                      text-align: center;
                      padding: 0px 10px 10px;
                    }


    .product-card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0px 0px 0px -40px;
    }
      .product-card > div:first-child {
        width: 74%;
        padding: 0px 20px 0px 40px;
      }
        .product-gallery-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
          padding: 0px 0px 25px;
        }
          .product-gallery {
            background: #e6b56a;
            background: -webkit-linear-gradient(right, #f5ca66, #e0ac6c);
            background: -moz-linear-gradient(right, #f5ca66, #e0ac6c);
            background: -ms-linear-gradient(right, #f5ca66, #e0ac6c);
            background: -o-linear-gradient(right, #f5ca66, #e0ac6c);
            background: linear-gradient(to right, #f5ca66, #e0ac6c);
            width: 74.104046%;
            padding: 3px;
            position: relative;
          }
            .product-gallery:before {
              content: "";
              display: block;
              height: 0px;
              padding: 58.11023622% 0% 0%;
            }
              .product-gallery.slick-initialized:before {
                display: none;
              }

              .product-gallery-image {
                position: relative;
                z-index: 1;
              }
                .product-gallery > .product-gallery-image {
                  position: absolute;
                  top: 3px;
                  left: 3px;
                  right: 3px;
                }
                  .product-gallery > .product-gallery-image:first-child {
                    z-index: 2;
                  }

                .product-gallery-image > div {
                  background: no-repeat 50% 50%/cover #e0e0e0;
                  position: relative;
                }
                  .product-gallery-image > div:before {
                    content: "";
                    display: block;
                    height: 0px;
                    padding: 58.11023622% 0% 0%;
                  }
                  .product-gallery-image > div a {
                    content: "";
                    display: block;
                    font-size: 0px;
                    cursor: pointer;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    z-index: 1;
                  }

          .product-gallery-pager {
            width: 25.8959538%;
            position: relative;
          }
            .product-gallery-pager-image {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: stretch;
              border: none !important;
              padding: 2px 2px 2px 22px;
              position: relative;
            }
              .product-gallery-pager > .product-gallery-pager-image:nth-child(n+4) {
                display: none;
              }

              .product-gallery-pager-image > div {
                flex-grow: 1;
                border: 2px solid transparent;
                margin: -2px;
                position: relative;

                -webkit-transition: border-color .2s ease 0s;
                -moz-transition: border-color .2s ease 0s;
                -o-transition: border-color .2s ease 0s;
                transition: border-color .2s ease 0s;
              }
                .product-gallery-pager > .product-gallery-pager-image:first-child > div,
                .product-gallery-pager-image + .product-gallery-pager-image.slick-current > div {
                  border-color: #e6b56a;
                }

                .product-gallery-pager-image > div:before {
                  content: "";
                  display: block;
                  border-top: 10px solid transparent;
                  border-right: 16px solid #e6b56a;
                  border-bottom: 10px solid transparent;
                  height: 0px;
                  width: 0px;
                  position: absolute;
                  top: 0px;
                  right: 100%;
                  bottom: 0px;
                  margin: auto;
                  opacity: 0;

                  -webkit-transition: opacity .2s ease 0s;
                  -moz-transition: opacity .2s ease 0s;
                  -o-transition: opacity .2s ease 0s;
                  transition: opacity .2s ease 0s;
                }
                  .product-gallery-pager > .product-gallery-pager-image:first-child > div:before,
                  .product-gallery-pager-image + .product-gallery-pager-image.slick-current > div:before {
                    opacity: 1;
                  }

                .product-gallery-pager-image > div > div {
                  background: no-repeat 50% 50%/cover #e0e0e0;
                  height: 0px;
                  padding: 60.5% 0% 0%;
                  position: relative;
                  cursor: pointer;
                }


      .product-card-right {
        font-size: 14px;
        background: #f0f0f0;
        border: 2px solid #185580;
        width: 26%;
        min-width: 26%;
        padding: 15px;
      }
        .product-prices-container > div + div {
          border-top: 1px solid #909090;
          margin: 10px 0px 0px;
          padding: 10px 0px 0px;
        }
          .product-prices-container > div > div:first-child {
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
          }

          .product-prices-container > div > table {
            border: none;
            width: 80%;
            padding: 0px;
            margin: 5px auto 0px;
          }
            .product-prices-container > div > table td {
              white-space: nowrap;
            }
            .product-prices-container > div > table td:first-child {
              font-size: 11px;
              font-weight: 500;
              text-align: right;
              vertical-align: baseline;
              padding: 0px 10px;
            }
            .product-prices-container > div > table td + td {
              font-size: 24px;
              font-weight: 600;
              text-align: right;
              vertical-align: baseline;
              padding: 0px 30px 0px 0px;
            }
              .product-prices-container > div > table td + td span {
                font-size: 14px;
                text-transform: uppercase;
                vertical-align: baseline;
              }


        .product-order-button {
          border-top: 1px solid #909090;
          text-align: center;
          padding: 15px 0px 0px;
          margin: 10px 0px 0px;
        }
          .product-order-button a {
            font-size: 16px;
            text-transform: uppercase;
          }


        .product-base-parameters {
          border-top: 1px solid #909090;
          margin: 15px 0px 0px;
          padding: 25px 0px 0px;
        }
          .product-base-parameters > div:first-child {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
          }
          .product-base-parameters > div + div {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-start;
            padding: 10px 0px 0px;
          }
            .product-base-parameters > div + div > div > div:first-child {
              font-weight: 500;
              padding: 0px 0px 2px;
            }
            .product-base-parameters > div + div > div > div + div {
              font-size: 16px;
              font-weight: 600;
            }

        .product-parameters-options {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-content: flex-start;
          align-items: flex-start;
          font-size: 13px;
          margin: 15px 0px 0px -15px;
        }
          .product-parameters-options > div {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 33.33333333%;
            padding: 10px 0px 0px 15px;
            position: relative;
          }
            .product-parameters-options > div:before {
              content: "";
              background: url('/i/product-option-cross-icon.svg') no-repeat 50% 50%/contain transparent;
              width: 10px;
              min-width: 10px;
              height: 10px;
              margin: 0px 6px 0px 0px;
              /*position: relative;
              z-index: 2*/;
            }
            /*.product-parameters-options > div span {
              position: relative;
              z-index: 1;
            }*/

          .product-parameters-options > div.selected {
            font-weight: 600;
          }
            .product-parameters-options > div.selected:before {
              background-image: url('/i/product-option-tick-icon.svg');
            }

            /*.product-parameters-options > div.selected span:before {
              content: "";
              background: #fafafa;
              border-radius: 3px;
              position: absolute;
              top: -2px;
              left: -22px;
              right: -6px;
              bottom: -2px;
              z-index: -1;
            }*/


    .product-options-table {
      font-size: 14px;
      border: 1px solid #979797;
      width: 100%;
      padding: 0px;
      margin: 0px 0px 20px;
    }
      .product-options-table td {
        padding: 10px 15px;
      }
      .product-options-table td:first-child {
        border-right: 1px solid #979797;
        width: 18%;
      }
      .product-options-table tr:nth-child(2n+1) td {
        background: #fff;
      }
      .product-options-table tr:nth-child(2n) td {
        background: #f0f0f0;
      }


    #fast-order-popup-basket.form-popup-container .form-block-container {
      width: auto;
      max-width: 800px;
    }
      .fast-order-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
      }
        .product-fast-order-info {
          width: 430px;
          margin-right: 25px;
        }
          .product-fast-order-name {
            font-weight: 600;
            margin-bottom: 25px;
          }

          .product-fast-order-image {
            background: no-repeat 50% 50%/contain transparent;
          }
            .product-fast-order-image:before {
              content: "";
              display: block;
              height: 0px;
              padding-top: 58.11023622%;
            }

        #fast-order-popup-basket .fast-order-wrapper .form-container {
          width: 255px;
        }


    .hide-after:before,
    .hide-after ~ * {
      display: none !important;
    }


    .js-show-hide {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }
      .js-show-hide > div:first-child {
        line-height: 1;
        position: relative;
        order: 1;
      }
        .js-show-hide.active > div:first-child {
          order: 3;
        }

        .js-show-hide > div:first-child > span {
          color: #1e6496;
          border-bottom: 1px dashed #1e6496;
          font-weight: 500;
          cursor: pointer;
        }
          .js-show-hide > div:first-child > span:hover {
            border-bottom-color: transparent;
          }

      .js-show-hide > div + div {
        display: none;
        position: relative;
        order: 2;
      }
        .js-show-hide.active > div + div {
          display: block;
        }


    .incut {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      background: #185580;
      background: -webkit-linear-gradient(right, #2473ab, #06263c);
      background: -moz-linear-gradient(right, #2473ab, #06263c);
      background: -ms-linear-gradient(right, #2473ab, #06263c);
      background: -o-linear-gradient(right, #2473ab, #06263c);
      background: linear-gradient(to right, #2473ab, #06263c);
      padding: 4px;
      position: relative;
    }
      .incut:before,
      .incut > div {
        flex: 1 1 auto;
      }
      .incut:before {
        content: "";
        font-size: 0px;
        background: url('/f/upload/incut-icon.svg') no-repeat 50% 50%/40% #fafafb;
        min-width: 90px;
        max-width: 90px;
        margin-right: 4px;
      }

      .incut > div {
        background: #fafafb;
        width: 100%;
        padding: 18px 20px 20px;
      }



  /* Contact */
  .contact-map-block-container {
    background: #eee;
    position: relative;
  }
    .contact-map-placeholder {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 1;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
      .contact-map-placeholder:before {
        content: "Загрузка карты...";
        margin: 0px 0px 0px 350px;
      }

    #contact-map-container {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 2;
    }
    .contact-info-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 1210px;
      min-height: 520px;
      padding: 30px 0px 30px 10px;
      margin: 0px auto;
    }
      .contact-info-wrapper {
        display: inline-block;
        background: #fff;
        box-shadow: inset 0 0 0 1px rgba(0,0,0,.15), 0 5px 15px -7px rgba(0,0,0,.5);
        width: 350px;
        padding: 25px;
        position: relative;
        z-index: 3;
      }
        .contact-info-wrapper > div + div {
          padding-top: 20px;
        }
          .contact-info-label {
            font-size: 18px;
            font-weight: 600;
            padding: 0px 0px 4px;
          }

          .contact-phones > div > div + div,
          .contact-emails > div > div + div {
            padding-top: 2px;
          }
          .contact-address > div > div + div {
            padding-top: 12px;
          }
            .contact-phones div:not(.contact-worktime) > a {
              font-size: 20px;
              font-weight: 500;
            }

            .contact-worktime {
              font-size: 14px;
              font-weight: 300;
            }

          .contact-address {
            white-space: nowrap;
          }

          .contact-emails a {
            font-weight: 500;
            line-height: 1.1;
            text-decoration: underline;
            cursor: pointer;
          }
            .contact-emails a:hover {
              text-decoration: none;
            }


  .contact-map-block-container + .wrapper {
    margin-top: 30px;
  }



  /* Footer */
  .footer-callback-container {
    background: #f0f0f0;
    margin: 20px 0px 25px;
  }
    .footer-callback-container .form-block-container {
      background-color: transparent;
      /*max-width: 900px;*/
      margin: 0px auto;
      padding: 25px;
    }
      .footer-callback-container .subsubheader {
        margin-top: -2px;
        text-align: center;
      }

      .footer-callback-container .form-wrapper {
        margin: -10px 0px 0px -10px;
      }
        .footer-callback-container form {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
        }
          .footer-callback-container .form-row,
          .footer-callback-container .form-button,
          .footer-callback-container #form-success-message {
            padding: 10px 0px 0px 10px;
            margin: 0px;
          }

          .footer-callback-container .form-elements {
            flex: 2 1 auto;
          }
            .footer-callback-container .form-elements > div:first-child {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-start;
            }
            .footer-callback-container .form-elements > div:first-child .form-row {
              flex: 10 0 auto;
            }
            .footer-callback-container .form-elements > div:first-child .form-row-name-captcha {
              flex: 0 10 auto;
            }
              .footer-callback-container .form-elements > div:first-child .form-label {
                display: none;
              }

          .footer-callback-container .form-buttons {
            flex: 1 1 auto;
            margin: 0px;
          }
            .footer-callback-container .form-button,
            .footer-callback-container .button-style {
              width: 100%;
            }

          .footer-callback-container #form-success-message {
            text-align: center;
          }


  .footer {
    color: #fff;
    background: #185580;
    background: -webkit-linear-gradient(left, #2473ab, #06263c);
    background: -moz-linear-gradient(left, #2473ab, #06263c);
    background: -ms-linear-gradient(left, #2473ab, #06263c);
    background: -o-linear-gradient(left, #2473ab, #06263c);
    background: linear-gradient(to left, #2473ab, #06263c);
    padding: 20px 0px 0px;
    position: relative;
    z-index: 1;
  }
    .footer-content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      padding: 0px 0px 20px;
      margin-left: -25px;
    }
      .footer-left-container {
        flex: 1 1 auto;
        margin-left: 25px;
      }
        .footer-left-container > div {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }
          .footer-logo {
            margin: 3px 0px 0px;
          }

          .footer-phones {
            padding-top: 15px;
          }
            .footer-phones > div + div {
              padding-top: 3px;
            }
              .footer-phones a {
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                white-space: nowrap;
              }

          .footer-buttons {
            padding-top: 10px;
          }
            .footer-buttons .button-style {
              font-size: 12px;
            }


      .footer-menu-container {
        flex: 3 1 auto;
      }
        .footer-menu-container > ul {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }
          .footer-menu-container > ul > li {
            padding: 0px 0px 0px 25px;
          }
            .footer-menu-container .dropmarker {
              display: none;
            }

            .footer-menu-container > ul > li > div {
              border-bottom: 1px solid #fff;
              padding: 0px 0px 8px;
            }

              .footer-menu-container > ul li > div a,
              .footer-menu-container > ul li > div span {
                color: #fff;
                font-size: 14px;
              }
              .footer-menu-container > ul li > div a {
                cursor: pointer;

                -webkit-transition: color .2s ease-in-out 0s;
                -moz-transition: color .2s ease-in-out 0s;
                -o-transition: color .2s ease-in-out 0s;
                transition: color .2s ease-in-out 0s;
              }
              .footer-menu-container > ul li > div a:hover,
              .footer-menu-container > ul li.active > div a {
                color: #e6b56a;
              }
              .footer-menu-container > ul li.active > div a {
                font-weight: 600;
              }

              .footer-menu-container > ul > li > div a,
              .footer-menu-container > ul > li > div span {
                font-size: 16px;
                font-weight: 500;
                white-space: nowrap;
              }


            .footer-menu-container > ul > li > ul {
              padding: 8px 0px 0px;
            }
              .footer-menu-container > ul > li > ul > li {
                padding: 2px 0px 0px;
              }


    .footer-copyrights {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      font-size: 14px;
      border-top: 1px solid #fff;
      padding: 20px 0px;
    }